/* .headerNavlink{
    white-space: nowrap;
}

#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.app-main {
    flex: 1;
    padding: 60px 20px 20px;
}

.logo {
    width: 1000px;
    height: 1000px;
} */

.usernameField{
    background: transparent;
}
.passwordField{
    background: transparent;
}


.overlay{
    text-align:center;
    margin: 0 auto;
    opacity:1;
    background-color:  #00205b  ;
    position:fixed;
    width:100%;
    height:100%;
    z-index:1;
}

.MuiButton-root
{
    background:blue !important;
    color: white !important;
}
.MuiButton-text
{
    background:green !important
}
.MuiButton-primary
{
    background:blue !important
}

